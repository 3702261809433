.icon {
    width: 85px;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.container-header {
    margin-bottom: 30px;
}

.side-nav {
    margin-left: 50%;
    margin-top: 70px;
}

@media (max-width: 1800px) {
    .side-nav {
        margin-left: 40%;
        margin-top: 70px;
    }
}

@media (max-width: 1700px) {
    .side-nav {
        margin-left: 35%;
        margin-top: 70px;
    }
}

@media (max-width: 1300px) {
    .side-nav {
        margin-left: 25%;
        margin-top: 70px;
    }
}

@media (max-width: 1000px) {
    .side-nav {
        margin-left: 0%;
        margin-top: 70px;
    }
}


.card-content {
    text-align: center;
    transition: 0.3s !important;
}

.card-content:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25), -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.card-container{
    --position: relative;
    --width:100%;
    margin-bottom: 30px;
}

.card-container:after {
    --content: "";
    --display: block;
    --padding-bottom: 100%;
}

.card-content-square {
    --position: absolute;
    --width: 100%;
    --height: 100%;
  }

.card-inside{
    text-align: center;
}

.tools{
    margin-left: 10%;
    margin-right: 10%;
}

@media (max-width: 1700px) {
    .tools{
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media (max-width: 1300px) {
    .side-nav {
        margin-left: 25%;
        margin-top: 70px;
    }
}

@media (max-width: 1000px) {
    .side-nav {
        margin-left: 0%;
        margin-top: 70px;
    }
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}